<template>
  <div>
    <loading-comp v-if="$store.state.isLoading"></loading-comp>
    <loading-inplay-comp v-if="$store.state.iild"></loading-inplay-comp>
<!--    <member-login-window v-if="$store.state.isShowLoginPanel"></member-login-window>-->
    <member-register-window v-if="$store.state.isShowRegisterPanel"></member-register-window>
    <not-memeber-customer-comp v-if="$store.state.isShowNotMemberCustomerePanel"></not-memeber-customer-comp>
    <Header></Header>
    <div class="mainwarp">
      <left></left>
      <div class="centerwarp" id="centerwarp">
        <router-view></router-view>
      </div>
      <right></right>
      <scroll-top-comp></scroll-top-comp>
    </div>
    <Footer></Footer>
  </div>


</template>

<script>
import '@/assets/css/front/afrontcommon.css'
import '@/assets/css/front/afrontindex.css'
import '@/assets/css/front/afrontmedia.css'
import Left from "@/views/afront/Left.vue";
import Right from "@/views/afront/Right.vue";
import Footer from "@/views/afront/Footer.vue";
import Header from "@/views/afront/Header.vue";
import BScroll from "better-scroll";
import MouseWheel from '@better-scroll/mouse-wheel'
import ScrollBar from '@better-scroll/scroll-bar'
import LoadingInplayComp from "@/components/LoadingInplayBetComp.vue";
import NotMemeberCustomerComp from "@/views/afront/user/NotMemeberCustomerComp.vue";
import LoadingComp from "@/components/LoadingComp.vue";
import LoginComp from "@/components/LoginComp.vue";
import MemberLoginWindow from "@/views/afront/MemberLoginWindow.vue";
import {getSiteStatus, logout} from "@/network/userRequest";
import MemberRegisterWindow from "@/views/afront/MemberRegisterWindow.vue";
import MyUncheckedMessageComp from "@/views/afront/notice/MyUncheckedMessageComp.vue";
import ScrollTopComp from "@/components/ScrollTopComp.vue";

BScroll.use(MouseWheel)
BScroll.use(ScrollBar)
export default {
  name: "AFront",
  components: {
    ScrollTopComp,
    MyUncheckedMessageComp,
    MemberRegisterWindow,
    MemberLoginWindow,
    LoginComp,
    LoadingComp,
    NotMemeberCustomerComp,
    LoadingInplayComp,
    Header, Footer, Right, Left},
  data(){
    return{
      s1:null,
      s2:null,
      s3:null,
    }
  },
  methods: {
    initScroll() {
      let cw = document.querySelector(".centerwarp");
      // this.s1 = new BScroll(cw, {
      //   click: true, freeScroll: true, mouseWheel: true,
      //   scrollbar: {
      //     fade: this.$store.state.ismobile,
      //     interactive: true,
      //     scrollbarTrackClickable: true
      //   }
      // })

      // let lw = document.querySelector(".leftwarp");
      // this.s2 = new BScroll(lw, {
      //   click: true, freeScroll: true, mouseWheel: true,
      //   scrollbar: {
      //     fade: this.$store.state.ismobile,
      //     interactive: true,
      //     scrollbarTrackClickable: true
      //   }
      // })

      let rw = document.querySelector(".rightwarp");
      // this.s3 = new BScroll(rw, {
      //   click: true, freeScroll: true, mouseWheel: true,
      //   scrollbar: {
      //     fade: this.$store.state.ismobile,
      //     interactive: true,
      //     scrollbarTrackClickable: true
      //   }
      // })

    },
    browser() {
      let u = navigator.userAgent
      let app = navigator.appVersion
      return {         //移动终端浏览器版本信息
        trident: u.indexOf('Trident') > -1, //IE
        presto: u.indexOf('Presto') > -1, //opera
        webKit: u.indexOf('AppleWebKit') > -1, //Apple,Google
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //FireFox
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //
        iPhone: u.indexOf('iPhone') > -1, //
        iPad: u.indexOf('iPad') > -1, //
        webApp: u.indexOf('Safari') == -1//
      }
    }
  },
  created() {
    getSiteStatus().then(res => {
      if (!res.data.success) {
        logout()
        if (this.$route.path.indexOf('main') == -1) {
          this.$router.replace("/front/main")
        }
        this.$store.state.isMaintaining = true;
        this.$store.state.maintainingMessage = res.data.msg
        this.$store.state.isLogin = false;
        this.$store.state.isShowLoginPanel = true;
      } else {
        this.$store.state.isMaintaining = false;
        this.$store.state.maintainingMessage = '';
      }
    })
    this.$store.state.ismobile = this.browser().mobile
    setTimeout(() => {
      //this.initScroll()
      this.$bus.$on('gameTypeClicked', () => {
       // this.s1.refresh()
       // this.s2.refresh()
       // this.s3.refresh()
      })
    }, 800);
  }
}
</script>
<style scoped>
@import url("../../assets/css/front/afrontindex.css");
@import url("../../assets/css/front/afrontmedia.css");

</style>